export const CHANGE_ACCESS_TOKEN = 'CHANGE_MEMBER_TOKEN' as const
export const CHANGE_REFRESH_TOKEN = 'CHANGE_REFRESH_TOKEN' as const

export type AccessTokenType = (e: string) => void
export type RefreshTokenType = (e: string) => void

export const changeAccessToken = (payload: string) => ({
  type: CHANGE_ACCESS_TOKEN,
  payload,
})

export const changeRefreshToken = (payload: string) => ({
  type: CHANGE_REFRESH_TOKEN,
  payload,
})

type MemberAction =
  | ReturnType<typeof changeAccessToken>
  | ReturnType<typeof changeRefreshToken>

export default MemberAction
