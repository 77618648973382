import GlobalAction from '../action/global-action'
import GlobalModel from '../model/global-model'

const init_state: GlobalModel = {
  count: 0,
}

const Global = (
  state: GlobalModel = init_state,
  action: GlobalAction,
): GlobalModel => {
  switch (action.type) {
    case 'INCREMENT_COUNT':
      return { count: state.count + action.payload }
    case 'DECREMENT_COUNT':
      return { count: state.count - action.payload }
    default:
      return state
  }
}

export default Global
