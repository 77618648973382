import rootReducer from './index'
import { persistReducer, persistStore } from 'redux-persist'
import { createStore } from 'redux'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default function configureStore() {
  const store = createStore(persistedReducer)
  const persistor = persistStore(store)
  return { store, persistor }
}
