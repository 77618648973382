const SET_ALLTIME = 'SET_ALLTME'
const SET_ANSWER = 'SET_ANSWER'
const SET_HIGHTLIGHT = 'SET_HIGTHLIGHT'
const SET_RESET = 'SET_REST'

export const allTime = (alltime: number) => ({ type: SET_ALLTIME, alltime })
export const handleAnswer = (an: Array<any>) => ({ type: SET_ANSWER, an })
export const hightlight = (hightlight: object) => ({
  type: SET_HIGHTLIGHT,
  hightlight,
})
export const reset = () => ({ type: SET_RESET })

const initialState: any = {
  alltime: 0,
  answer: [],
  hightlight: [],
}

export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_ALLTIME:
      return {
        ...state,
        alltime: action.alltime,
      }
    case SET_ANSWER:
      return {
        ...state,
        answer: state.answer.concat(action.an),
      }
    case SET_HIGHTLIGHT:
      return {
        ...state,
        hightlight: [...state.hightlight, action.hightlight],
      }
    case SET_RESET:
      return initialState
    default:
      return state
  }
}
