import React from 'react'
import ReactDOM from 'react-dom'
import EduBrain from './edu-brain'
import * as Sentry from '@sentry/react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from './store/store'

const { store, persistor } = configureStore()

Sentry.init({
  dsn: "https://0fe1bfaf0f5944f8ae7756fd9759f073@o4505521901142016.ingest.sentry.io/4505526144204800",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <EduBrain />
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById('edu-brain'),
)
