import MemberAction, {
  CHANGE_ACCESS_TOKEN,
  CHANGE_REFRESH_TOKEN,
} from '../action/member-action'
import MemberTokenModel from '../model/member-token-model'

const init_token_state: MemberTokenModel = {
  token: '',
  refresh_token: '',
}

export const memberTokenReducer = (
  state: MemberTokenModel = init_token_state,
  action: MemberAction,
) => {
  switch (action.type) {
    case CHANGE_ACCESS_TOKEN:
      return { ...state, token: action.payload }

    case CHANGE_REFRESH_TOKEN:
      return { ...state, refresh_token: action.payload }

    default:
      return { ...state }
  }
}

export default memberTokenReducer
