import React, { useMemo } from 'react'
import { audioType } from '../components/test/re-test-alert-sound-tutorial'
import { UE, UR, US } from '../shared/short'
import multimediaPlay from '../static/img/icon/multimedia-play.png'
import multimediaStop from '../static/img/icon/multimedia-stop.png'

const Lab = () => {
  // useState
  const [onPlay, setOnPlay] = US<audioType>('NOT_PLAYED')
  const [audiotime, setAudioTime] = US(Infinity)
  const [time, setTime] = US(0)
  // useRef
  const audioRef = UR<HTMLAudioElement>(null)
  const count_down_timer = UR<any>(null)

  const bar = useMemo(() => {
    return (100 * time) / audiotime
  }, [time])

  // Function
  const end = () => {
    setTimeout(() => {
      setTime(audiotime)
    }, 500)

    setTimeout(() => {
      reset()
    }, 1000)
  }

  const reset = () => {
    audioRef.current?.load()
    setTime(0)
    clearTimeout(count_down_timer.current)
    setOnPlay('NOT_PLAYED')
  }

  UE(() => {
    switch (onPlay) {
      case 'NOT_PLAYED':
        reset()
        break
      case 'PLAYING':
        audioRef.current?.play()
        break
    }
  }, [onPlay])

  return (
    <div className='audio'>
      {onPlay === 'NOT_PLAYED' ? (
        <button
          id='play'
          onClick={() => setOnPlay('PLAYING')}
          onTouchEnd={() => setOnPlay('PLAYING')}
        >
          <img src={multimediaPlay} alt='오디오 듣기 버튼' />
        </button>
      ) : (
        <button
          id='pause'
          onClick={() => setOnPlay('NOT_PLAYED')}
          onTouchEnd={() => setOnPlay('NOT_PLAYED')}
        >
          <img src={multimediaStop} alt='오디오 일시정지 버튼' />
        </button>
      )}

      <div className='time-bar'>
        <div className='crr-time' style={{ width: `${bar}%` }}>
          <audio
            id='audio-sample'
            src={process.env.PUBLIC_URL + '/audio/audio_sample1.mp3'}
            ref={audioRef}
            onLoadedData={(e) =>
              setAudioTime(Math.ceil(e.currentTarget.duration))
            }
            autoPlay={true}
            onTimeUpdate={(e) => setTime(e.currentTarget.currentTime)}
            onEnded={end}
          />
        </div>
        {/* <input type='range' /> */}
      </div>
      <span style={{ display: 'inline-block', width: '20px' }}>
        {onPlay === 'NOT_PLAYED'
          ? Math.ceil(audiotime) + 's'
          : Math.ceil(audiotime - time) + 's'}
      </span>
    </div>
  )
}

export default Lab
