import React from 'react'
import logo from '../static/img/jamong.png'

class LoadingPaging extends React.Component<{}, {}, any> {
  render() {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={logo} alt='logo' />
      </div>
    )
  }
}

export default LoadingPaging
