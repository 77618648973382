import { lazy, Suspense, useContext, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Route, Switch, topbar } from 'react-router-loading'
import LoadingPaging from './components/loading-paging'
import './style/init.scss'
import './style/common.scss'
import './style/style.scss'
import './style/input.scss'
import './style/layout.scss'
import './style/components.scss'
import './style/custom.scss'
// import './style/page/popup.scss'
import './style/alert.scss'
import Lab from './pages/lab'

const Index = lazy(() => import('./pages/index'))
const Login = lazy(() => import('./pages/login/login'))
const AutoLogin = lazy(() => import('./pages/login/auto-login'))
const LoginTeacher = lazy(() => import('./pages/login/login-teacher'))
const Join = lazy(() => import('./pages/join/join-type'))
const JoinParents = lazy(() => import('./pages/join/join-parents'))
const JoinChild = lazy(() => import('./pages/join/join-child'))
const JoinTeacher = lazy(() => import('./pages/join/join-teacher'))
const JoinComplete = lazy(() => import('./pages/join/join-complete'))
const FindID = lazy(() => import('./pages/find-i-p/find-id'))
const FindIDResult = lazy(() => import('./pages/find-i-p/find-id-result'))
const FindPassword = lazy(() => import('./pages/find-i-p/find-password'))
const PasswordReset = lazy(() => import('./pages/find-i-p/password-reset'))
const ReleaseDormancy = lazy(() => import('./pages/find-i-p/release-dormancy'))

const RegularIndex = lazy(() => import('./pages/regular-exam/index'))
const RegularIndexType1 = lazy(() => import('./pages/regular-exam/index-type1'))
const RegularIndexType2 = lazy(() => import('./pages/regular-exam/index-type2'))
const RegularIndexType3 = lazy(() => import('./pages/regular-exam/index-type3'))
const RegularIndexType4 = lazy(() => import('./pages/regular-exam/index-type4'))
const RegularIndexType5 = lazy(() => import('./pages/regular-exam/index-type5'))

const RegularStudy = lazy(() => import('./pages/regular-exam/study'))

const Mypage = lazy(() => import('./pages/mypage/mypage'))
const MyPageStudent = lazy(() => import('./pages/mypage/mypage-student'))
const MyPageTeacher = lazy(() => import('./pages/mypage/mypage-teacher'))

const Grade = lazy(() => import('./pages/grade/grade'))

// const TestLayout = lazy(() => import('./pages/test/test-layout'))
const ExamPage = lazy(() => import('./pages/test/exam-page'))
const TypeA = lazy(() => import('./pages/test/type-a'))
const TypeB = lazy(() => import('./pages/test/type-b'))
const TypeC = lazy(() => import('./pages/test/type-c'))
const TypeD = lazy(() => import('./pages/test/type-d'))
const TypeE = lazy(() => import('./pages/test/type-e'))
const RetestRouter = lazy(() => import('./pages/test/re-test-router'))
const Multitype = lazy(() => import('./pages/test/multitype'))
const ExamIntro = lazy(() => import('./pages/test/exam-intro'))

const TestComplete = lazy(() => import('./pages/test/complete'))

const Management = lazy(() => import('./pages/management/management'))
const ReviewIndex = lazy(() => import('./pages/review/index'))

const Tutorial = lazy(() => import('./pages/tutorial/tutorial'))
const Tutorial2 = lazy(() => import('./pages/tutorial2/tutorial'))
const TutorialTypeA = lazy(() => import('./pages/tutorial/type-a'))
const TutorialTypeB = lazy(() => import('./pages/tutorial/type-b'))
const TutorialTypeC = lazy(() => import('./pages/tutorial/type-c'))
const TutorialTypeD = lazy(() => import('./pages/tutorial/type-d'))
const TutorialTypeE = lazy(() => import('./pages/tutorial/type-e'))
const Tutorial2TypeA = lazy(() => import('./pages/tutorial2/type-a'))
const Tutorial2TypeB = lazy(() => import('./pages/tutorial2/type-b'))
const Tutorial2TypeC = lazy(() => import('./pages/tutorial2/type-c'))
const Tutorial2TypeD = lazy(() => import('./pages/tutorial2/type-d'))
const Tutorial2TypeE = lazy(() => import('./pages/tutorial2/type-e'))

const Footer = lazy(() => import('./pages/footer'))
const Index2 = lazy(() => import('./pages/index2'))
const Landing = lazy(() => import('./pages/index'))

const EduBrain = () => {
  topbar.config({
    autoRun: true,
    barThickness: 3,
    barColors: {
      0: '#fb3067',
    },
    className: 'topbar',
  })

  return (
    <Suspense fallback={<LoadingPaging />}>
      <BrowserRouter>
        <Switch maxLoadingTime={600} loadingScreen={LoadingPaging}>
          <Route path='/lab' exact component={Lab} />
          <Route path='/landing' exact component={Landing} loading />
          {/*랜딩 페이지*/}
          <Route path='/' exact component={Index} loading />
          {/* 로그인 - 연동 로그인 */}
          <Route path='/auto-login' exact component={AutoLogin} loading />
          {/* 로그인 - 부모, 학생*/}
          <Route path='/login' exact component={Login} loading />
          {/* 로그인 - 선생님 */}
          <Route path='/loginTeacher' exact component={LoginTeacher} loading />
          {/* 회원가입 */}
          <Route path='/join' component={Join} loading />
          <Route path='/joinParents' component={JoinParents} loading />
          <Route path='/joinchild' component={JoinChild} loading />
          <Route path='/joincomplete' component={JoinComplete} loading />
          <Route path='/jointeacher' component={JoinTeacher} loading />
          {/* 아이디 / 비밀번호 찾기 */}
          <Route path='/findid' component={FindID} loading />
          <Route path='/findidresult' component={FindIDResult} loading />
          <Route path='/findpassword' component={FindPassword} loading />
          <Route path='/passwordreset' component={PasswordReset} loading />
          {/* 휴면계정 해지 */}
          <Route path='/releasedormancy' component={ReleaseDormancy} loading />
          {/* 클래스코드 미 입력시 */}
          <Route path='/regularMain' component={RegularIndex} loading />
          <Route path='/regularStudy' component={RegularStudy} loading />
          {/* 클래스코드 입력시 */}
          {/* 정기시험기간 X */}
          <Route
            path='/regularMainRegist'
            component={RegularIndexType1}
            loading
          />
          <Route
            path='/regularMainStudy'
            component={RegularIndexType2}
            loading
          />
          {/* 정기시험기간 O */}
          <Route
            path='/regularMainExam'
            component={RegularIndexType3}
            loading
          />
          <Route
            path='/regularMainTested'
            component={RegularIndexType5}
            loading
          />
          {/* 정기시험 시험 이후 서베이 */}
          <Route
            path='/regularMainSurvey'
            component={RegularIndexType4}
            loading
          />
          {/* 마이페이지 */}
          <Route path='/mypage' component={Mypage} loading />
          <Route path='/mypagestudent' component={MyPageStudent} loading />
          <Route path='/mypageteacher' component={MyPageTeacher} loading />
          {/* 시험 테스트 분석 */}
          <Route path='/grade' component={Grade} loading />
          {/* 시험 */}
          {/* <Route path='/testlayout' component={TestLayout} /> */}
          <Route path='/exam' component={ExamPage} loading />
          {/* 튜토리얼 */}
          <Route path='/typea' component={TypeA} loading />
          {/* Listening */}
          <Route path='/typeb' component={TypeB} loading />
          <Route path='/typec' component={TypeC} loading />
          {/* Reading */}
          <Route path='/typed' component={TypeD} loading />
          {/* Grammar */}
          <Route path='/typee' component={TypeE} loading />
          {/* 시험완료 */}
          <Route path='/testcomplete' component={TestComplete} loading />
          {/* 재 시험 */}
          {/* 정답 선택 5초 이후 다음페이지 이동 */}
          <Route path='/re-test' component={RetestRouter} loading />
          <Route path='/ExamIntro' component={ExamIntro} loading />

          {/* 시험 튜토리얼 페이지 */}
          <Route path='/tutorial' component={Tutorial} loading />
          <Route path='/tutorialTypea' component={TutorialTypeA} loading />
          {/* Listening */}
          <Route path='/tutorialTypeb' component={TutorialTypeB} loading />
          <Route path='/tutorialTypec' component={TutorialTypeC} loading />
          {/* Reading */}
          <Route path='/tutorialTyped' component={TutorialTypeD} loading />
          {/* Grammar */}
          <Route path='/tutorialTypee' component={TutorialTypeE} loading />

          <Route path='/multitype' component={Multitype} loading />
          {/* 정규 리뉴얼로 인한 임시 튜토리얼 */}
          <Route path='/tutorial2' component={Tutorial2} loading />
          <Route path='/tutorial2Typea' component={Tutorial2TypeA} loading />
          {/* Listening */}
          <Route path='/tutorial2Typeb' component={Tutorial2TypeB} loading />
          <Route path='/tutorial2Typec' component={Tutorial2TypeC} loading />
          {/* Reading */}
          <Route path='/tutorial2Typed' component={Tutorial2TypeD} loading />
          {/* Grammar */}
          <Route path='/tutorial2Typee' component={Tutorial2TypeE} loading />

          <Route path='/multitype' component={Multitype} loading />

          {/* 선생님 메인페이지 */}
          <Route path='/management' component={Management} loading />
          {/* 학생 학습 페이지 */}
          <Route path='/reviewindex' component={ReviewIndex} loading />
          {/* Footer */}
          <Route path='/footer' component={Footer} loading />
          {/* 경로 가이드 페이지 */}
          <Route path='/index2' component={Index2} loading />
          {/* <Route path='/test' component={Test} /> */}
        </Switch>
      </BrowserRouter>
    </Suspense>
  )
}

export default EduBrain
