import { combineReducers } from 'redux'
import reducer from './reducer'
import global from './reducer/global-reducer'
import memberTokenReducer from './reducer/member-reducer'

const rootReducer = combineReducers({
  reducer,
  global,
  memberTokenReducer,
})

export default rootReducer

export type RootState = ReturnType<typeof rootReducer>
